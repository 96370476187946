<!--
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-10-28 09:44:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\src\view\index\index.vue
-->
<template>
  <div class="container">
    <s-header />
    <div class="main">
      <div v-if="type == 'video'">
        <!-- 如果只有视频 -->
      </div>
      <div class="txtBox" v-else>
        <!-- 如果是文章 -->
        <div class="leftContent" :class="img.length==0?'all-article':''">
          <div class="topCrum" :style="img.length==0?'width: 100%;':'width: 855px;'">
            <a href="#/">首页</a>
            <span class="jiantou">></span>
            <a>{{content.title}}</a>
          </div>
          <div class="contentbox" :style="img.length==0?'width: 100%;':'width: 855px;'">
            <div class="title">
              <img src="../../static/images/mingtai/left_title.png"/>
              {{ content.title }}
              <img src="../../static/images/mingtai/right_title.png"/>
            </div>
            <video v-if="content.videoUrl" :src="content.videoUrl" class="video-content" controls/>
            <div v-else class="html-content" v-html="content.content"></div>
          </div>
        </div>
        <div class="rightImg" v-if="img.length>0">
          <a-icon type="left" class="arrow prev" @click="prevImg"/>
          <img :src="img[currentImgIndex]" />
          <a-icon type="right" class="arrow next" @click="nextImg"/>
        </div>
      </div>
    </div>
    <s-footer />
  </div>
</template>

<script>
import sHeader from "@/view/components/FakeHeader";
import sFooter from "@/view/components/Footer";

export default {
  components: {
    sHeader,
    sFooter,
  },
  data() {
    return {
      isShow: true,
      type: null,
      aticleId: "",
      img: [],
      currentImgIndex: 0,
      content: {
        id: "",
        title: "面部艾灸穴位图面部艾灸穴位图面部艾灸穴位图面",
        content:
          "艾草的真伪仅凭目测或者嗅觉是很难辨别的，有些艾草看起来质感均匀、颜色纯正，闻起来也没什么怪味，但也未必是真艾；相反有些艾草看起来似乎杂质较多，颜色未必好看，反而使用效果非常好，所以要想在鱼龙混杂的市",
        videoUrl: null,
      },
    };
  },
  created() {},
  mounted() {
    let id = this.$route.query.id;
    this.getData(id);
  },
  methods: {
    getData(id) {
      this.$api.getFreeArticleInfo({},{id:id}).then(res => {
        this.content = res.data
      })
    },
  },
};
</script>

<style lang="scss" scoped>
// 公共样式
.hide {
  display: none;
}
.show {
  display: block;
}
a {
  color: #656565;
}
* {
  font-family: "微软雅黑";
}
.video-content {
  width: 80%;
}
.html-content {
  // height: 90%;
  // overflow: auto;
  font-size: 16px;
}
.all-article {
    max-width: 1366px;
    width: 1366px;
    margin: 0 auto;
}
.container {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  .main {
    width: 100%;
    margin: 0 auto;
    height: calc(100% - 122px);
    box-sizing: border-box;
    display: flex;
    background-image: url("../../static/images/mingtai/rightImgBg.png");
    background-size: 100% 100%;
    .txtBox {
      width: 100%;
      height: 100%;
      display: flex;
      margin: 0 auto;
      .leftContent {
        flex: 60%;
        overflow: auto;
  
        background-color: white;
        padding: 20px 20px;
        box-sizing: border-box;
        .topCrum {
          color: #0d4066;
          font-size: 18px;
          cursor: pointer;
          float: right;
          a {
            color: #0d4066;
            margin-right: 12px;
            font-family: 微软雅黑;
            font-size: 16px;
          }
          .jiantou {
            margin-right: 12px;
          }
        }
        .contentbox {
          margin-top: 20px;
          height: 100%;
          float: right;
          // text-align: center;
          .title {
            font-size: 28px;
            color: #063a61;
            text-align: center;
            margin-bottom: 2%;
            img {
              width: 100px;
            }
          }
        }
      }
      .rightImg {
        flex: 40%;
        overflow: hidden;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 40px;
        text-align: center;
        position: relative;
        img {
          max-height: 100%;
          max-width: 100%;
          object-fit: contain;
        }
        .arrow {
          font-size: 50px;
          background-color: #bdbdbda3;
          bottom: 50%;
          position: absolute;
          cursor: pointer;
        }
        .prev {
          left: 0px;
        }
        .next {
          right: 0px;
        }
      }
    }
  }
}
</style>