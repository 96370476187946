<!--
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-10-28 13:14:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\src\view\index\index.vue
-->
<template>
  <div class="headerContainer">
    <div class="topBox">
      <img
        class="leftLogo"
        src="../../static/images/mingtai/topLogo.png"
      />
      <div class="logoTxt">洛阳明泰健康咨询有限公司 </div>
      <div class="rightItem">
        <a href="#/">首页</a>
        <a href="#/My">个人中心</a>
        <!-- <a>联系我们</a> -->
      </div>
    </div>
   
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Vue from 'vue';
export default {
  name:'sHeader',
  data() {
    return {

    };
  },
  created() {
    
  },
  methods: {
    ...mapActions(['Logout']),

    toLogOut(){
      this.$confirm({
          title: '提示',
          content: '真的要注销登录吗 ?',
          okText: '确定',
          cancelText: '取消',
          onOk: () => {
            return this.Logout({}).then(() => {
              setTimeout(() => {
                window.location.reload()
              }, 16)
            }).catch(err => {
              this.$message.error({
                title: '错误',
                description: err.message
              })
            })
          },
          onCancel () {
          }
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.headerContainer {
  width: 100%;
  height: 85px;
  background-color: #063A61;
  overflow: hidden;
  position: relative;
  .loginOut{
    position: absolute;
    right:30px;
    color:white;
    font-size:20px;
    top:50%;
    margin-top:-8px;
    cursor: pointer;
  }
  .topBox {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 1366px;
    width: 1366px;
    margin: 0 auto;
    .leftLogo {
      width:155px;
    }
    .logoTxt{
      color:white;
      margin-left: 35px;
      font-size: 24px;
      font-weight: bold;
      flex: 1;
    }
    .rightItem{
      font-size: 16px;
      cursor: pointer;
      justify-content: flex-end;
      text-align: right;
      span{
        margin-left: 40px;
      }
      a{
        margin-left:40px;
        color:white;
      }
    }
  }
}
</style>